/* eslint-disable camelcase*/
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { LeaderboardItemContainer, LeaderBoardItem } from '../styles';
import Waiting from '../../Waiting';
import { commaSeperator, getUnit, convertDateInTimezone, GetSortedData, convertMilesToKm } from '../../../utils/methods';
import Image from '../../Image';
import { ActivityContainer, ActivityInsight, UserName, ButtonWrapper, ButtonSurvior, CardsWrapper, LeaderboardCard } from './styles';
import { CoveredChallenge } from '../OverViewScreen/styles';
import { leaderboardPageLimit } from '../../../utils/constants';
import { ParticipantCount } from '../MyTaskTab/styles';
import { withTranslation } from 'react-i18next';
class SurvivorLeaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdatedImage: false,
      selectedOption: 'all',
      selectedActivityType: 'Steps',
      pageNumber: 1,
      isLoading: false
    }
  }

  componentDidMount() {
    const { selectedOption, pageNumber } = this.state;
    const { location: { pathname }, fetchChallengeCompetitors, challenge, fetchTeamChallenge } = this.props;
    const StringArray = pathname.split('/');
    fetchChallengeCompetitors(StringArray[(StringArray.length - 1)], challenge.event_type, leaderboardPageLimit, pageNumber, selectedOption, () => { this.changeLoading() });
    if (challenge.challenge_type === 'survivor' && challenge.challenge_mode == 'Team') {
      fetchTeamChallenge(StringArray[(StringArray.length - 1)], challenge.event_type);
    }
    this.checkBase64(this.props.profileImage);

  }

  changeLoading(bool) {
    this.setState({ isLoading: bool })
  }

  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      }
      else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.challenge !== nextProps.challenge) {
      const { challenge } = nextProps;
      this.props.fetchChallengeCompetitors(challenge.id, challenge.event_type, leaderboardPageLimit, this.state.pageNumber, this.state.selectedOption, () => { this.changeLoading() });
      if (challenge.challenge_type === 'survivor' && challenge.challenge_mode === 'Team') {
        nextProps.fetchTeamChallenge(challenge.id, challenge.event_type);
      }
    }

    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }

  showProfileImage = (user) => {
    const { userId, profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if (userId === user.uid) {
      if (isUpdatedImage) {
        return (<img src={profileImage} alt="avatar" />)
      }
      else {
        return (<Image image={profileImage} alt="avatar" />)
      }
    }
    else {
      return (<Image image={user.profile_image} alt="avatar" />)
    }
  };

  renderSurvivorChallengeLeaderBoard = (user, ChallengeType, valid, challenge) => (
    <React.Fragment>
      <ActivityContainer survivor>
        <UserName>{user.fullname}</UserName>
        <LeaderboardItemContainer>
          {
            user.weekly_marks && user.weekly_marks.length > 0 && user.weekly_marks.map((data, index) =>
              <LeaderBoardItem key={index} completed={data === 1} solo={challenge.challenge_mode === 'Solo'}>
                {
                  data === 1 ? <i className="fas fa-check" /> : <i className="fa fa-times" />
                }
              </LeaderBoardItem>
            )
          }
        </LeaderboardItemContainer>
      </ActivityContainer>
      <ActivityInsight challengeLeaderBoard survivor>
        {(challenge.challenge_status !== 'over' || valid) ?
          <CoveredChallenge challengeLeaderBoard survivor center>
            <span>
              {
                ChallengeType === 'Distance' ? getUnit(_.get(user, 'average', 0)) :
                  commaSeperator(Math.round(_.get(user, 'average', 0)))
              }&nbsp;
            </span>
            Daily Average
          </CoveredChallenge>
          :
          <CoveredChallenge challengeLeaderBoard survivor center>
            <span>
              {
                ChallengeType === 'Distance' ? getUnit(_.get(user, 'challenge_average', 0)) :
                  commaSeperator(Math.round(_.get(user, 'challenge_average', 0)))
              }&nbsp;
            </span>
            Daily Average
          </CoveredChallenge>
        }
        {
          <CoveredChallenge challengeLeaderBoard survivor center>
            <span>
              {user.challenge_total ? (
                ChallengeType === 'Distance' ? getUnit(user.challenge_total) :
                  commaSeperator(parseInt(user.challenge_total,10))) : 0}&nbsp;
            </span>
            Challenge Total
          </CoveredChallenge>}
      </ActivityInsight>
    </React.Fragment>
  );

  renderHeaderPanel = (rank) => {
    const { challenge, challengeCompetitors, teamChallenge } = this.props;
    if (challenge.challenge_mode === 'Solo' && rank >= 0) {
      const weekMarks = challengeCompetitors[rank] && challengeCompetitors[rank].weekly_marks && challengeCompetitors[rank].weekly_marks.filter((mark) => mark === 0);
      return (
        <div>
          <div>
            <span>{weekMarks && (weekMarks.length > 1 ? 'Oh No...' : 'Looking Good!')}</span>
          </div>
          <LeaderboardItemContainer auto>
            {
              challengeCompetitors[rank] && challengeCompetitors[rank].weekly_marks && challengeCompetitors[rank].weekly_marks.map((data, index) =>
                <LeaderBoardItem key={index} completed={data === 1}>
                  {
                    data === 1 ? <i className="fas fa-check" /> : <i className="fa fa-times" />
                  }
                </LeaderBoardItem>
              )
            }
          </LeaderboardItemContainer>
        </div>
      )
    } else if (challenge.challenge_mode === 'Team' && !_.isEmpty(teamChallenge)) {
      const weekMarks = teamChallenge.filter((marks) => marks === 0);
      return (
        <div>
          <div>
            <span>{weekMarks && (weekMarks.length > 1 ? 'Ohh No..' : 'Looking Good!')}</span>
          </div>
          <LeaderboardItemContainer auto>
            {
              teamChallenge && teamChallenge.map((data, index) =>
                <LeaderBoardItem key={index} completed={data === 1}>
                  {
                    data === 1 ? <i className="fas fa-check" /> : <i className="fa fa-times" />
                  }
                </LeaderBoardItem>
              )
            }
          </LeaderboardItemContainer>
        </div>
      )
    }
  };

  toggleRadioButton = (e) => {
    const { location: { pathname }, fetchChallengeCompetitors, challenge } = this.props;
    const StringArray = pathname.split('/');
    this.setState({ selectedOption: e, pageNumber: 1 }, () => {
      fetchChallengeCompetitors(StringArray[(StringArray.length - 1)], challenge.event_type, leaderboardPageLimit, this.state.pageNumber, this.state.selectedOption, () => { this.changeLoading() });
    });
  };

  onScroll = (e) => {
    const { pageNumber } = this.state;
    const { location: { pathname }, challengeCompetitors, competitorsCount, challenge } = this.props;
    const StringArray = pathname.split('/');
    const bottom = Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10)) === Math.round(e.target.clientHeight);
    if (bottom && challengeCompetitors.length < competitorsCount) {
      this.setState({
        pageNumber: pageNumber + 1,
        isLoading: true
      }, () => {
        this.props.fetchChallengeCompetitors(StringArray[(StringArray.length - 1)], challenge.event_type, leaderboardPageLimit, this.state.pageNumber, this.state.selectedOption, () => { this.changeLoading() });
      });
    }
  };
  render() {
    const { selectedOption } = this.state;
    const { challenge, networkError, userId, t, challengeAttendees } = this.props;
    if ((!(this.props.challengeCompetitors) || !userId) && !networkError) {
      return <Waiting />
    }
    const valid = convertDateInTimezone(challenge.end_date)._d >= moment()._d;
    let challengeCompetitors = [];
    if (challenge.challenge_status !== 'over' || valid) {
      challengeCompetitors = this.props.challengeCompetitors;
    }
    else if (this.props.challengeCompetitors.length > 0) {
      challengeCompetitors = this.props.challengeCompetitors;
      challengeCompetitors = this.props.challengeCompetitors.sort(GetSortedData('challenge_total'));
    }
    let today = moment()._d;
    let startDay = convertDateInTimezone(challenge.start_date)._d;
    if (challengeCompetitors.length === '0' && selectedOption === "all") {
      return <Waiting />
    }
    const DistanceUnit = localStorage.getItem('DISTANCE');
    return (
      <React.Fragment>
        {challenge && (challenge.challenge_status !== 'over') && (today < startDay)  ?
          <ParticipantCount >
            <div>{t("Total Participants")}</div>
            <div>{challengeAttendees.length}</div>
          </ParticipantCount > :
          (challengeCompetitors.length === '0' ? null :(challenge.challenge_status == 'joined'||challenge.challenge_status == 'over')?
            <CardsWrapper>
              <ButtonWrapper>
                <ButtonSurvior width={"9.8%"} background={selectedOption === "all" ? "#005C87" : "transparent"} shadow={selectedOption === "all"} color={selectedOption === "all" ? '#fff' : "#9c9c9c"} border={selectedOption === "all" ? '0' : "1px solid #9c9c9c"} onClick={() => this.toggleRadioButton('all')} value={'all'}>{t("All")}</ButtonSurvior>
                <ButtonSurvior width={"11.7%"} background={selectedOption === "survivors" ? "#005C87" : "transparent"} shadow={selectedOption === "survivors"} color={selectedOption === "survivors" ? '#fff' : "#9c9c9c"} border={selectedOption === "survivors" ? '0' : "1px solid #9c9c9c"} onClick={() => this.toggleRadioButton('survivors')} value={'survivors'}>{t("Survivor")}</ButtonSurvior>
                <div className='headingMain'>
                  <div className='leader'>{this.props.t("Top Leaders")}</div>
                  <div className='participantsCount'>{challengeCompetitors && challengeCompetitors.length} {this.props.t("Participants")}</div>
                </div>
              </ButtonWrapper>
              <CardsWrapper>
                {challengeCompetitors.map((userdata, index) =>
                  <LeaderboardCard key={index} positionColor={index===0?"#76AB78":index===1?"#FFBF73":index===2?"#F4AAA9":'#005C87'} border={index===0?"#76AB78":index===1?"#FFBF73":index===2?"#F4AAA9":0}>
                    <div>
                      <div>
                        {index===0&&<div className="topPositionCard">
                          <img src={'/public/images/behaviorChallenges/winner.png'} alt={'winner'} />
                        </div>}
                        {this.showProfileImage(userdata)}</div>
                      <div>
                        <div>{index + 1}</div>
                        <div>{userdata.fullname}</div>
                      </div>
                    </div>
                    <div>
                      <div>{commaSeperator(userdata.daily_average)} {this.props.t("Daily Avg")}</div>
                      <div>
                        <div>{challenge.event_type === "Total Steps" ? commaSeperator(userdata.steps) : challenge.event_type === "Total Distance" ?DistanceUnit === "KILOMETER"?commaSeperator(convertMilesToKm(userdata.distance)) :commaSeperator(userdata.distance) : commaSeperator(userdata.calories)}</div>
                        <div>{challenge.event_type === "Total Steps" ? this.props.t("Steps") : challenge.event_type === "Total Distance" ? DistanceUnit === "KILOMETER"?"Km":  this.props.t("Miles") : this.props.t("Calories")}</div>
                      </div>
                    </div>
                  </LeaderboardCard>)}
              </CardsWrapper>
            </CardsWrapper>:
            <ParticipantCount >
              <div>{t("Total Participants")}</div>
              <div>{challengeAttendees.length}</div>
            </ParticipantCount >)}
      </React.Fragment>
    )
  }
}

SurvivorLeaderboard.propTypes = {
  challenge: PropTypes.object.isRequired,
  fetchChallengeCompetitors: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  challengeCompetitors: PropTypes.array,
  networkError: PropTypes.string,
  userId: PropTypes.number.isRequired,
  profileImage: PropTypes.string.isRequired,
  fetchTeamChallenge: PropTypes.func.isRequired,
  teamChallenge: PropTypes.array,
  competitorsCount: PropTypes.number,
  t: PropTypes.func,
  challengeAttendees: PropTypes.array
};

const mapStateToProps = (state) => ({
  challengeCompetitors: state.challenges.challengeCompetitors,
  teamChallenge: state.challenges.teamChallenge,
  competitorsCount: state.challenges.competitorsCount,
  challengeAttendees: state.challenges.challengeAttendees
});

export default connect(mapStateToProps)(withTranslation()(SurvivorLeaderboard));