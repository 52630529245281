/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FavoriteContainerV2 } from './styles';
import { getChallengeTimeToShow } from '../../utils/methods';
import { joinOrLeaveChallenge } from '../../redux/actions';
import { ContainerFirst, } from '../EventDetails/styles';
import { VideoContainer } from '../MainEvents/OnDemandVideoDetailsPage/styles';
import {ImageUrl} from '../../utils/constants';
import { withTranslation } from 'react-i18next';

class DetailedChallenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
    }
  }

  componentDidMount() {
    const { challenge, liveChallengeStatus } = this.props;
    this.interval = setInterval(() => {
      this.setState({
        time: liveChallengeStatus ? [] : getChallengeTimeToShow(challenge)
      })
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.challenge !== this.props.challenge) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.setState({
          time: this.props.liveChallengeStatus ? [] : getChallengeTimeToShow(this.props.challenge)
        })
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  joinChallenge = (challengeId, isJoined) => {
    const { joinOrLeaveChallenge } = this.props;
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined
    };
    joinOrLeaveChallenge(data);
  };

  render() {
    const { challenge } = this.props;
    return (
      <ContainerFirst >
        <VideoContainer height={"100%"}>
          <FavoriteContainerV2 font={challenge.challenge_type === "new_group" ? "24px" : "20px"}>
            <div>{challenge.title}</div>
            <div>
              <div className="image"> <img src={`${ImageUrl}/${challenge.image}`} alt="back-arrow" /></div>
            </div>
          </FavoriteContainerV2>
          <div className='pointValue'>
            <div className='text'>{this.props.t("Point Value")}</div>
            <div className='point'>+{challenge.challenge_point}pts</div>
          </div>
        </VideoContainer>
      </ContainerFirst>
    );
  }
}

DetailedChallenge.propTypes = {
  challenge: PropTypes.object.isRequired,
  joinOrLeaveChallenge: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  navigateEdit: PropTypes.func,
  isAdmin: PropTypes.bool,
  userId: PropTypes.number,
  syncTime: PropTypes.string,
  liveChallengeStatus: PropTypes.bool,
  isDummyChallenge: PropTypes.bool,
  role: PropTypes.string,
  userPermissions: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  isLoading: state.challenges.isLoading,
  isDummyChallenge: state.challenges.isDummyChallenge
});

const mapDispatchToProps = (dispatch) => ({
  joinOrLeaveChallenge: (data) => dispatch(joinOrLeaveChallenge(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(DetailedChallenge));