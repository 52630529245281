import {RadioButtonContainer} from "../../RegistrationForm/styles";
import styled, {css} from "styled-components";
const RegularFont = 'Rubik-Regular';
const MediumFont = 'Rubik-Medium';

const RadioButtonWrapper = styled(RadioButtonContainer)`
  display: block;
  float: left;
  width: 100%;
  justify-content: space-between;
  padding: 22px 10px 12px 0px;
  align-items: center;
  @media (max-width:1140px) {
    display: flex;
    float: none;
  }
  @media (max-width: 520px) {
     flex-direction: row;
     width: 100%;
  }
  @media (max-width:400px) {
    padding: 22px 10px 22px 10px;
  }
  @media (min-width:1001px) and (max-width:1040px) {
    padding: 22px 10px 22px 10px;
  }
  @media (max-width: 360px){
    flex-direction: column
  }
`;

const LeaderBoardSection = styled.div`
  float: left;
  width: 100%;
  overflow-y: auto;
  height: auto;
  max-height: calc(940px - 120px);
  margin-top: ${(marginTop) => marginTop ? marginTop : '0px'};
  

  @media (max-width: 500px) {
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    
    @media (max-width: 500px) {
      width: 5px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background: #c6c2c2;
    cursor: pointer;
    border-radius: 2.5px;
  }

  ::-webkit-scrollbar-track {
    background: #f2f0f0;
    border-radius: 2.5px;
  }
`;

const RankContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  float: left;
  width: 100%;
  padding: 15px 15px;
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  margin-bottom: 10px;
  & > div:last-child {
    margin-bottom: 0;
  }
  img {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    
    @media (max-width: 500px) {
      width: 40px;
      height: 40px;
    }
  }
`;
const ActivityContainer = styled.div`
  float: left;
  width: 60%;
  margin-left: 10px;
  @media(max-width: 1255px) and (min-width:1001px) {
    width: ${(props) => props.survivor ? 'calc(100% - 60px)' : '50%'};
  }
  @media (max-width: 700px) {
    width: ${(props) => props.survivor ? 'calc(100% - 60px)' : '50%'};
  }
  @media(max-width: 400px) {
    text-align: center
    width: 100%;
    margin-left: 0;
  }

  ${({ survivorChallenge }) => survivorChallenge && css`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    > div:first-child {
      white-space: nowrap;
      overflow: hidden;
      padding-right: 5px;

      > span {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `}
  
`;
const ActivityInsight = styled.div`
   float: right;
   width: calc(40% - 60px);
   height: 100%;
   min-height: 64px;
   display: ${(props) => props.survivor ? 'flex' : 'block'}
   flex-direction: column;
   justify-content: space-between;
   @media(max-width: 1255px) and (min-width:1001px) {
      flex-direction: row;
      width: ${(props) => props.survivor ? 'calc(100% - 60px)' : 'calc(50% - 60px)'};
      justify-content: space-between;
      min-height: 0;
      margin-left: ${(props) => props.survivor ? '60px' : '0'};
      margin-top: ${(props) => props.survivor ? '10px' : '0'}
   }
   
    & > div:first-child {
      @media(max-width: 1255px) and (min-width:1001px) {
        text-align: ${(props) => props.survivor ? 'left' : 'right'}
      }  
      @media(max-width: 700px) {
        text-align: ${(props) => props.survivor ? 'left' : 'right'}
      }
      @media(max-width:400px) {
        text-align: ${(props) => props.survivor ? 'left' : 'center'}
      }  
    }
   @media (max-width: 700px) {
    flex-direction: row;
    width: ${(props) => props.survivor ? '100%' : 'calc(50% - 60px)'};
    justify-content: space-between;
    min-height: 0;
    margin-left: ${(props) => props.survivor ? '60px' : '0'};
    margin-top: ${(props) => props.survivor ? '10px' : '0'};
   }
   @media (max-width: 500px) {
    margin-left: ${(props) => props.survivor ? '50px' : '0'};
   }
   @media(max-width: 400px) {
    width: 100%;
    margin-top: 5px;
    margin-left: 0;
   }
`;
const TopContent = styled.div`
   display: flex;
   align-items:center;
   justify-content: center;
   width: 100%;
`;
const UserName = styled.span`
  float: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #282C37;
  font-family: ${RegularFont};
  text-transform: capitalize;
  
  @media (max-width: 400px) {
    display: block;
    width: 100%
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
const ActivityBar = styled.div`
  float: left;
  height: 6px;
  width: 100%;
  background-color: rgba(109, 129, 156, 0.2);
  border-radius: 30px;
  display: inline-block;
  
  @media(max-width: 1280px) {
    width: 100%;
  }
`;
const InnerActivityBar = styled.div`
  height: 6px;
  width: ${(props) => props.width};
  background:${(background) => background ? background : '#7EC341'}
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  
  ${(props) => props.width === '100%' && css`
    border-radius: 30px;
  `}
`;
const Ranking = styled.span`
  display: block;
  width: 100%;
  float: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: rgba(40, 44, 55, 0.5);
  font-family: ${MediumFont};
`;

const ButtonWrapper = styled.div`
width: 100%;
display: flex;
margin: 15px 0px 15px 0px;
flex-wrap: wrap;
.headingMain{
  width:100%;
  display:flex;
  justify-content:space-between;
  margin:25px 0px 15px 0px;
  .leader{
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color:#005C87;
  }
  .participantsCount{
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color:#005C87;
  }
}
`;

const ButtonSurvior = styled.div`
min-width:${({width}) => width ? width : '11.7%'}
height: 30px;
background:${({background}) => background ? background : 'transparent'}
display:flex;
justify-content: center;
align-items: center;
margin-right: 10px;
border:${({border}) => border ? border  : ''};
cursor: pointer;
box-shadow:${({shadow}) => shadow ? "0px 8px 25px 0px #005C8766"  : ''};
font-family: 'Rubik-Medium';
font-size: 16px;
line-height: 20px;
color: ${({color}) => color ? color : '#fff'}
border-radius: 3px;
padding: 5px;
`;

const CardsWrapper = styled.div`
width: 100%;
display: block;
`;

const LeaderboardCard = styled.div`
width: 100%;
display: flex;
background: ${({background}) => background ? "#fff" : '#fff'};
border-radius: 6px;
margin-bottom: 10px;
border: 1px solid #005C874D;
.topPositionCard{
  width: 100%;
  height: 0px;
  position:absolute;
>img{
  width: 30px;
  height: 30px;
  position: relative;
  left: 12px;
  bottom: 24px;
}
}
>div:nth-child(1){
  width: 50%;
  display: flex;
  >div:nth-child(1){
    width: 60px;
    height: 60px;
    margin: 10px 15px 10px 10px;
    border: ${({border}) => border  ? `3px solid ${border}` : ''};
    background: ${({border}) => border  ? border : ''};
    border-radius:6px;
    >img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
  >div:nth-child(2){
    display: flex;
    margin: auto;
    margin-left: 0px;
    display: block;
    >div:nth-child(1){
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color: #fff;
      color: ${({positionColor}) => positionColor ? '#005C87' : '#005C87'};
      margin-bottom: 10px;
    }
    >div:nth-child(2){
      font-family: Rubik;
      font-size: 16px;
      line-height: 20px;
      color: #0D4270;
      color: ${({positionColor}) => positionColor ? '#005C87' : '#005C87'};
    }
  }
}
>div:nth-child(2){
  width: 50%;
  display: block;
  padding-right: 15px;
  margin:auto;
  >div:nth-child(1){
    display: flex;
    justify-content: flex-end;
    font-family: Rubik;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    color: ${({positionColor}) => positionColor ? '#005C87' : '#005C87'};
    margin-bottom: 10px;
  }
  >div:nth-child(2){
    width: 100%;
    display: flex;
    justify-content: flex-end;
    >div:nth-child(1){
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color: #fff;
      color: ${({positionColor}) => positionColor ? positionColor : '#005C87'};
    }
    >div:nth-child(2){
      font-family: 'Rubik';
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      margin-left: 5px;
      color: ${({positionColor}) => positionColor ? '#005C87' : '#005C87'};
    }
  }
}
`;

export {RadioButtonWrapper, LeaderBoardSection, RankContainer, ActivityContainer, ActivityInsight, TopContent, UserName, ActivityBar, InnerActivityBar, Ranking, ButtonWrapper, ButtonSurvior, CardsWrapper, LeaderboardCard}